import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { User } from 'app/core/user/user.types';
import { map, of, switchMap } from 'rxjs';

export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
    const router: Router = inject(Router);
    const authService: AuthService = inject(AuthService);

    // Check the authentication status
    return authService.isLoggedIn().pipe(
        switchMap((authenticated) => {
            if (!authenticated) {
                const redirectURL =
                    state.url === '/' ? '' : `redirectTo=${state.url}`;
                const urlTree = router.parseUrl(
                    `/autorizacao/entrar?${redirectURL}`
                );
                return of(urlTree);
            }

            if (route.data.role === 'ADMIN') {
                return authService.user$.pipe(
                    map((user: User) => {
                        if (user?.admin) {
                            return true;
                        } else {
                            // Redirect to a specific URL if the user is not an admin
                            return router.parseUrl('');
                        }
                    })
                );
            } else {
                // Allow the access
                return of(true);
            }
        })
    );
};
