<div class="sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
    <!-- Header -->
    <div
        class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b">
        <!-- Loader -->

        <!-- Title -->
        <div class="text-4xl font-extrabold tracking-tight">
            <!-- Painel de Análise e Visualização -->
            Dashboard
        </div>
    </div>

    <div
        class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between pt-2 px-6 md:pt-4 border-b">
        <form [formGroup]="form" class="flex-auto">
            <mat-form-field class="w-52 mr-4">
                <input class="input" placeholder="Descrição" matInput formControlName="description" />
            </mat-form-field>
            <mat-form-field class="w-52 mr-4">
                <input class="input" placeholder="Email do requisitante" matInput formControlName="emailUserRequest" />
            </mat-form-field>
            <mat-form-field class="w-52 mr-4">
                <mat-select placeholder="Tipo" formControlName="serviceType">
                    <mat-option [value]="null">-- Nenhum --</mat-option>
                    <mat-option *ngFor="let item of types" [value]="item._id" [matTooltip]="item.name">
                        {{ item.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="w-52 mr-4">
                <mat-select placeholder="Status" formControlName="status">
                    <mat-option [value]="null">-- Nenhum --</mat-option>
                    <mat-option *ngFor="let item of status" [value]="item[0]">{{ item[1] }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="sm:w-40 w-full mr-5">
                <mat-select placeholder="Data" #matSelectDate formControlName="optionDate"
                    (selectionChange)="onSelectionChange($event)">
                    <mat-option [value]="null">-- Nenhum --</mat-option>
                    <mat-option value="today">Hoje</mat-option>
                    <mat-option value="yesterday">Ontem</mat-option>
                    <mat-option value="last7days">Últimos 7 dias</mat-option>
                    <mat-option value="last15days">Últimos 15 dias</mat-option>
                    <mat-option value="lastMonth">Último mês</mat-option>
                    <mat-option value="interval">Intervalo</mat-option>
                    <mat-option value="specific">Específico</mat-option>
                </mat-select>
            </mat-form-field>
            <ng-container *ngIf="matSelectDate.value === 'specific'">
                <mat-form-field color="accent" class="w-50 px-1">
                    <mat-label>Data</mat-label>
                    <input matInput [matDatepicker]="picker_specific" formControlName="date_start"
                        placeholder="DD/MM/AAAA" />
                    <mat-datepicker-toggle matIconSuffix [for]="picker_specific"></mat-datepicker-toggle>
                    <mat-datepicker #picker_specific color="primary"></mat-datepicker>
                    <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:calendar-days'"></mat-icon>
                </mat-form-field>
            </ng-container>
            <ng-container *ngIf="matSelectDate.value === 'interval'" class="flex sm:flex-row flex-col w-auto">
                <mat-form-field color="accent" class="w-50 px-1">
                    <mat-label>De</mat-label>
                    <input matInput [matDatepicker]="picker_start" formControlName="date_start"
                        placeholder="DD/MM/AAAA" />
                    <mat-datepicker-toggle matIconSuffix [for]="picker_start"></mat-datepicker-toggle>
                    <mat-datepicker #picker_start color="primary"></mat-datepicker>
                    <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:calendar-days'"></mat-icon>
                </mat-form-field>
                <mat-form-field color="accent" class="w-50 px-1">
                    <mat-label>Até</mat-label>
                    <input matInput [matDatepicker]="picker_end" formControlName="date_end" placeholder="DD/MM/AAAA" />
                    <mat-datepicker-toggle matIconSuffix [for]="picker_end"></mat-datepicker-toggle>
                    <mat-datepicker #picker_end color="primary"></mat-datepicker>
                    <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:calendar-days'"></mat-icon>
                </mat-form-field>
            </ng-container>
            <button *ngIf="matSelectDate.value === 'specific' || matSelectDate.value === 'interval'"
                class="mb-auto mt-auto mx-1" mat-flat-button [color]="'primary'" name="salvar"
                [disabled]="!areDatesValid()" (click)="filterDate()">
                Buscar
            </button>
        </form>
    </div>
    <div class="flex flex-col overflow-auto">
        <!-- Loading statusCounts -->
        <div *ngIf="!statusCounts" class="flex h-full flex-col items-center justify-center m-5">
            <div class="text-center">
                <mat-spinner></mat-spinner>
            </div>
        </div>

        <div *ngIf="statusCounts" class="flex flex-wrap justify-around m-5">
            <!-- Resumo-->
            <div
                class="bg-card flex flex-auto flex-col overflow-hidden rounded-2xl p-6 shadow sm:col-span-2 md:col-span-4">
                <div class="flex flex-col items-start justify-between sm:flex-row">
                    <div class="truncate text-lg font-medium leading-6 tracking-tight">
                        Resumo
                    </div>
                </div>
                <div class="mt-8 grid w-full grid-flow-row grid-cols-1 gap-6 sm:mt-4 lg:grid-cols-2">
                    <!-- Distribuição de Serviços-->
                    <div class="flex flex-auto flex-col">
                        <div class="text-secondary font-medium">
                            Distribuição de Serviços
                        </div>
                        <div class="flex flex-auto flex-col">
                            <div id="chart-polar">
                                <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart"
                                    [labels]="chartOptions.labels" [fill]="chartOptions.fill"
                                    [stroke]="chartOptions.stroke" [responsive]="chartOptions.responsive">
                                </apx-chart>
                            </div>
                        </div>
                    </div>
                    <!-- Overview -->
                    <div class="flex flex-col">
                        <div class="text-secondary font-medium">
                            Visão geral
                        </div>
                        <div class="mt-6 grid flex-auto grid-cols-4 gap-4">
                            <!-- Total -->
                            <div class="col-span-2 flex flex-col items-center justify-center rounded-2xl bg-indigo-50 px-1 py-8 text-indigo-800 dark:bg-white dark:bg-opacity-5 dark:text-indigo-400 hover:shadow-lg hover:scale-105 transition-transform duration-200 cursor-pointer"
                                (click)="setStatus(null)">
                                <div class="text-5xl font-semibold leading-none tracking-tight sm:text-7xl">
                                    {{ totalServices }}
                                </div>
                                <div class="mt-1 text-sm font-medium sm:text-lg">
                                    Total
                                </div>
                            </div>
                            <!-- CLOSED -->
                            <div class="col-span-2 flex flex-col items-center justify-center rounded-2xl bg-green-50 px-1 py-8 text-green-800 dark:bg-white dark:bg-opacity-5 dark:text-green-500 hover:shadow-lg hover:scale-105 transition-transform duration-200 cursor-pointer"
                                (click)="setStatus('CLOSE')">
                                <div class="text-5xl font-semibold leading-none tracking-tight sm:text-7xl">
                                    {{ statusCounts?.CLOSE }}
                                </div>
                                <div class="mt-1 text-sm font-medium sm:text-lg">
                                    Concluídos
                                </div>
                            </div>
                            <!-- PENDING -->
                            <div class="text-secondary col-span-2 flex flex-col items-center justify-center rounded-2xl bg-gray-100 px-1 py-8 dark:bg-white dark:bg-opacity-5 sm:col-span-1 hover:shadow-lg hover:scale-105 transition-transform duration-200 cursor-pointer"
                                (click)="setStatus('PENDING')">
                                <div class="text-5xl font-semibold leading-none tracking-tight">
                                    {{ statusCounts?.PENDING }}
                                </div>
                                <div class="mt-1 text-center text-sm font-medium">
                                    Pendentes
                                </div>
                            </div>
                            <!-- IN_PROGRESS -->
                            <div class="text-secondary col-span-2 flex flex-col items-center justify-center rounded-2xl bg-gray-100 px-1 py-8 dark:bg-white dark:bg-opacity-5 sm:col-span-1 hover:shadow-lg hover:scale-105 transition-transform duration-200 cursor-pointer"
                                (click)="setStatus('IN_PROGRESS')">
                                <div class="text-5xl font-semibold leading-none tracking-tight">
                                    {{ statusCounts?.IN_PROGRESS }}
                                </div>
                                <div class="mt-1 text-center text-sm font-medium">
                                    Em andamento
                                </div>
                            </div>
                            <!-- FILED -->
                            <div class="text-secondary col-span-2 flex flex-col items-center justify-center rounded-2xl bg-gray-100 px-1 py-8 dark:bg-white dark:bg-opacity-5 sm:col-span-1 hover:shadow-lg hover:scale-105 transition-transform duration-200 cursor-pointer"
                                (click)="setStatus('FILED')">
                                <div class="text-5xl font-semibold leading-none tracking-tight">
                                    {{ statusCounts?.FILED }}
                                </div>
                                <div class="mt-1 text-center text-sm font-medium">
                                    Protocolados
                                </div>
                            </div>
                            <!-- CLOSE -->
                            <div class="text-secondary col-span-2 flex flex-col items-center justify-center rounded-2xl bg-gray-100 px-1 py-8 dark:bg-white dark:bg-opacity-5 sm:col-span-1 hover:shadow-lg hover:scale-105 transition-transform duration-200 cursor-pointer"
                                (click)="setStatus('CLOSE')">
                                <div class="text-5xl font-semibold leading-none tracking-tight">
                                    {{ statusCounts?.CLOSE }}
                                </div>
                                <div class="mt-1 text-center text-sm font-medium">
                                    Fechados
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex flex-col">
            <!-- Loading services -->
            <div *ngIf="!services" class="flex h-full flex-col items-center justify-center mat-elevation-z8">
                <div class="text-center m-5">
                    <mat-spinner></mat-spinner>
                </div>
            </div>
            <mat-table *ngIf="services" [dataSource]="services" class="mat-elevation-z8">
                <ng-container matColumnDef="description">
                    <mat-header-cell *matHeaderCellDef> Descrição </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{ element.description }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="user_request">
                    <mat-header-cell *matHeaderCellDef> Usuário requisitante </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{ element.user_request.email }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="user_rt">
                    <mat-header-cell *matHeaderCellDef> Responsáveis </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <ng-container *ngFor="let user of element.user_rt; let i = index">
                            <span>{{ user.displayName }}</span>
                            <span *ngIf="i !== element.user_rt.length - 1">, &nbsp;</span>
                        </ng-container>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef> Ações </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <button class="min-w-10 min-h-7 h-7 px-2 leading-6" mat-stroked-button matTooltip="Detalhe"
                            [color]="'primary'" [routerLink]="['/seus-servicos','datalhamento', element._id]">
                            <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:folder-open'"></mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
            <mat-paginator #pagination [length]="length" [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize"
                [pageIndex]="pageIndex" showFirstLastButtons aria-label="Selecione a página"
                (page)="pageEvent = onPageChange($event)">
            </mat-paginator>
        </div>
    </div>
</div>