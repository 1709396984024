import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { HomeAdminComponent } from '../admin/home-admin/home-admin.component';

@Component({
    selector: 'app-home',
    standalone: true,
    imports: [CommonModule, HomeAdminComponent],
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit {
    user: User;

    constructor(private _userService: UserService) {}

    ngOnInit(): void {
        this._userService.user$.pipe().subscribe((user: User) => {
            this.user = user;
        });
    }
}
