import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    //Admin
    {
        id: 'admin',
        title: 'Administrador',
        type: 'collapsable',
        link: '/administrador',
        icon: 'heroicons_outline:user-group',
        children: [
            {
                id: 'dashboard',
                title: 'Dashboard',
                type: 'basic',
                icon: 'mat_outline:dashboard',
                link: '/',
            },
            {
                id: 'users',
                title: 'Usuários',
                type: 'basic',
                icon: 'heroicons_outline:users',
                link: '/administrador/usuarios',
            },
            {
                id: 'layers',
                title: 'Camadas',
                type: 'basic',
                icon: 'heroicons_outline:globe-americas',
                link: '/administrador/camadas',
            },
            {
                id: 'rasters',
                title: 'Rasters',
                type: 'basic',
                icon: 'heroicons_outline:flag',
                link: '/administrador/rasters',
            },
            {
                id: 'ferramenta-relatorios',
                title: 'Ferra. Relatórios',
                type: 'basic',
                icon: 'heroicons_outline:clipboard',
                link: '/administrador/ferramenta-relatorios',
            },
            {
                id: 'servicos',
                title: 'Serviços Requisitados',
                type: 'basic',
                icon: 'mat_outline:work_outline',
                link: '/administrador/servicos',
            },
        ],
    },
    {
        id: 'mapa-cidade',
        title: 'Mapa',
        type: 'basic',
        icon: 'heroicons_outline:map',
        link: '/mapa-cidade',
    },
    {
        id: 'projeto-usuario',
        title: 'Meus Projetos',
        type: 'basic',
        icon: 'heroicons_outline:folder-open',
        link: '/projeto-usuario',
    },
    {
        id: 'meus-relatorios',
        title: 'Meus Relatórios',
        type: 'basic',
        icon: 'heroicons_outline:clipboard',
        link: '/meus-relatorios/lista',
    },
    {
        id: 'servicos-usuario',
        title: 'Meus Serviços',
        type: 'basic',
        icon: 'heroicons_outline:briefcase',
        link: '/seus-servicos',
    },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];
